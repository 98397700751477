@import '../variables';
@import '../mixin';

.page-container.about {
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.6;

    & h1 {
        font-weight: bold;
    }

    & img {
        transition: .3s;
        object-fit: cover;
        max-height: 400px;
        border-radius: 3px;

        &:hover {
            opacity: .8 ;
            transition: .3s;
        }
    }

    .subtitle {
        font-size: $font-size-base * 2;
        font-weight: 300;
    }

    .about-us-intro {
        height: 480px;
    }

    .about-us-intro-gallery,
    .about-us-prominent-foodytos-gallery,
    .about-us-achievements-gallery,
    .about-us-review-gallery,
    .about-us-media-gallery,
    .about-us-saving-gallery,
    .about-us-review-gallery {
        & > div {
            @include mobile {
                width: 100%;
            }

        }

        img {
            //width: 400px;
            //height: 400px;
            width: 300px;
            height: 300px;
            margin: 1px 1px;

            @include mobile {
                width: 100% !important;
                height: 310px;
            }
        }
    }

    .about-us-media-gallery {
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }

    .about-us-prominent-foodytos-gallery,
    .about-us-media-gallery {
        & > div {
            @include tablet {
                // text-align: center !important;
            }
        }
    }

    .about-us-prominent-foodytos-gallery,
    .about-us-media-gallery,
    .about-us-saving-gallery {
        & > div > div {
            @include mobile {
                width: 100%;
            }
        }
    }

    .about-us-achievements-gallery,
    .about-us-review-gallery {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include mobile {
            justify-content: center !important;
        }
    }

    .about-us-aim {
        .box_topic {
            min-height: 240px !important;
        }
    }

    .about-us-prominent-foodytos-gallery,
    .about-us-media-gallery,
    .about-us-saving-gallery {
        & > div:nth-child(2) {
            direction: rtl;

            & p {
                direction: ltr;
            }
        }
    }

    .about-us-meet-gallery {
        --gap: 2px;
        --num-cols: 3;
        --row-height: 310px;

        display: grid;
        grid-template-columns: repeat(var(--num-cols), 1fr);
        grid-auto-rows: var(--row-height);
        gap: var(--gap);

        & > div > picture > img {
            width: 100%;
            height: 100%;
        }

        @include mobile {
            --num-cols: 1;
            --row-height: 230px;
        }
    }

    .section-media {
        .articles {
            .box_topic {
                img {
                    margin: 0 auto 20px;
                }

                @include mobile {
                    min-height: auto !important;
                    padding-bottom: 15px;
                }
            }
        }
    }

    .section-purpose {
        .box_topic {
            @include mobile {
                min-height: auto !important;
                padding-bottom: 15px;
            }
        }
    }

    .about-us-prominent-foodytos-gallery {
        .row {
            @include mobile {
                margin-left: 0px;
            }
        }
    }

    .about-us-saving-gallery {
        .row {
            @include mobile {
                margin-left: 0px;
            }
        }
    }

    .about-us-media-gallery {
        .row {
            @include mobile {
                margin-left: 0px;
            }
        }
    }
}